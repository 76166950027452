import type { FC } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.scss';
import RentForm from './forms/rent/RentForm';
import ServiceForm from './forms/service/ServiceForm';
import VosForm from './forms/vos/VosForm';

interface IAppProps {
  // eslint-disable-next-line
  domElement: any;
}

const App: FC<IAppProps> = ({
  domElement
}) => {
  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={<ServiceForm />}
        />
        <Route
          path="/vos"
          element={<VosForm domElement={domElement} />}
        />
        <Route
          path="/rent"
          element={<RentForm />}
        />
      </Routes>
    </Router>
  );
};

export default App;
