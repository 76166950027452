import classNames from 'classnames';
import type { FC } from 'react';
import { useContext } from 'react';
import { useRef, useState } from 'react';
import React from 'react';

import Address from '../../../components/Address';
import CartPortal from '../../../components/CartPortal';
import ContentNavigation from '../../../components/ContentNavigation';
import Button from '../../../components/ui/Button';
import ConfigVosContext from '../../../service/context/config-vos';
import useAppStoreVos from '../../../service/store/appVos';
import useOrderStoreVos from '../../../service/store/orderVos';
import { validateOrderVos } from '../../../utils/validate';

interface IStepCustomerDataProps {
  placeOrder: () => Promise<void>;
}

const StepCustomerData: FC<IStepCustomerDataProps> = ({
  placeOrder
}) => {
  const appContext = useContext(ConfigVosContext);
  const errorRef = useRef<null | HTMLDivElement>(null);
  const [ showErrors, setShowErrors ] = useState(false);
  const { step, setStep } = useAppStoreVos(state => state);

  const {
    order,
    setValue: setOrderValue
  } = useOrderStoreVos(state => state);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setOrderValue(event.target.value, event.target.name);
  };
  const validOrder = validateOrderVos(order);

  const handleCtaClick = (): void => {
    if (validOrder && placeOrder) {
      void placeOrder();
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ContentNavigation
        step={step}
        setStep={setStep}
        showBackButton
      />
      <div className="box">
        <div className="box-content">
          <div ref={errorRef}>
            {showErrors && !validOrder && (
              <div className="error-box">
                Bitte fülle aller Pflichtfelder aus.
              </div>
            )}
          </div>
          <Address
            showError={showErrors}
            propertyKey="address"
            address={order?.address}
            onChange={handleInputChange}
            showReferenceNumber
            referenceNumberLabel={appContext?.settings?.reference_number_label}
            referenceNumber={order?.reference_number}
            customerNumber={order?.customer_number}
            showCustomerNumber
            showCountrySelect
          />
          <div className="box-cta">
            <Button
              className={classNames('btn-xl', {
                'btn-disabled': !validOrder
              })}
              onClick={handleCtaClick}
              hasRightIcon
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt verbindlich beauftragen
            </Button>
          </div>
          <CartPortal>
            <Button
              className={classNames('btn-xl', {
                'btn-disabled': !validOrder
              })}
              onClick={handleCtaClick}
              hasRightIcon
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt verbindlich beauftragen
            </Button>
          </CartPortal>
        </div>
      </div>
    </>
  );
};

export default StepCustomerData;
