import classNames from 'classnames';
import { toNumber } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import { useRef, useState } from 'react';
import React from 'react';

import CartPortal from '../components/CartPortal';
import ContentNavigation from '../components/ContentNavigation';
import ProductInfoCard from '../components/ProductInfoCard';
import ServiceProviderLogo from '../components/ServiceProviderLogo';
import BoxModal from '../components/ui/BoxModal';
import Button from '../components/ui/Button';
import '../components/ui/Input.scss';
import Textarea from '../components/ui/Textarea';
import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import { COST_APPROVAL_FREE_LIMIT } from '../utils/constants';
import { scrollToRef } from '../utils/scroll';
import { getServiceProvider } from '../utils/service_provider';

const StepProductInfo: FC = () => {
  const config = useContext(ConfigContext);
  const costApprovalAlertRef = useRef<null | HTMLDivElement>(null);
  const errorRef = useRef<null | HTMLDivElement>(null);
  const [ showCostApprovalAlert, setShowCostApprovalAlert ] = useState(false);
  const [ showErrors, setShowErrors ] = useState(false);
  const {
    description,
    cost_approval,
    bridgingDeviceRequired,
    isComplaint
  } = useOrderStore(state => state.order) || {};
  const setOrderValue = useOrderStore(state => state.setValue);
  const setStep = useAppStore(state => state.setStep);
  const { name: serviceProviderName } = getServiceProvider();
  const isValid = !!description;
  const showComplaintButtons = !!config?.complaint_form;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const valueToSet = event.target.type === 'range' ? parseInt(event.target.value, 10) : event.target.value;

    setOrderValue(valueToSet, event.target.name);
  };

  const handleCtaClick = (): void => {
    if (isValid) {
      if (
        toNumber(cost_approval) >= COST_APPROVAL_FREE_LIMIT
        || showCostApprovalAlert
      ) {
        void setStep(4);
        scrollToRef();
      } else {
        setShowCostApprovalAlert(true);

        setTimeout(() => {
          costApprovalAlertRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ContentNavigation>
        <Button
          onClick={handleCtaClick}
          className={classNames('btn-dark', {
            'btn-disabled': !isValid
          })}
          hasRightIcon
          // disabled={!description}
        >
          Weiter zu Zubehör
        </Button>
      </ContentNavigation>
      <div className="box">
        {serviceProviderName && (
          <div className="box-header">
            <div className="service-provider-logo">
              <ServiceProviderLogo />
            </div>
            Dieses Gerät wird von {serviceProviderName} bearbeitet.
          </div>
        )}
        <div className="box-content">
          <div className="form-grid">
            <div className="styled-input">
              <label>Deine Auswahl:</label>
              <div className="item-box">
                <ProductInfoCard />
              </div>
            </div>
            <div className="styled-input styled-input-input-range">
              <label>Kostenfreigabe für sofortige Reparatur:</label>
              <div className="item-box">
                <div className="input-range-wrap">
                  <div>0€</div>
                  <input
                    type="range"
                    name="cost_approval"
                    value={cost_approval}
                    onChange={handleInputChange}
                    step="10"
                    min="0"
                    max="700"
                  />
                  <div>{cost_approval}€</div>
                </div>
              </div>
            </div>
          </div>
          {showComplaintButtons && (
            <div className="form-grid">
              <div className="styled-input">
                <div className="styled-input">
                  <label>Es handelt sich um eine Reklamation oder Garantiefall?</label>
                  <div className="buttons-wrap">
                    <button
                      className={classNames({ active: isComplaint })}
                      onClick={() => setOrderValue(true, 'isComplaint')}
                    >
                      Ja
                    </button>
                    <button
                      className={classNames({ active: !isComplaint })}
                      onClick={() => setOrderValue(false, 'isComplaint')}
                    >
                      Nein
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="form-grid">
            <div>
              <div ref={errorRef}>
                {showErrors && !isValid && (
                  <div className="error-box">
                    Bitte fülle aller Pflichtfelder aus.
                  </div>
                )}
              </div>
              <Textarea
                value={description}
                name="description"
                onChange={handleInputChange}
                wrapperClass={classNames({
                  'has-error': showErrors && !description
                })}
                label="Beschreiben Sie ihr Anliegen:*"
                placeholder="z.B kurze Fehlerbeschreibung: Meine Presse presst nicht..."
              />
            </div>
          </div>
          <div className="form-grid">
            <div className="styled-input">
              <div className="styled-input">
                <label>Wird ein Überbrückungsgerät benötigt?</label>
                <div className="buttons-wrap">
                  <button
                    className={classNames({ active: bridgingDeviceRequired })}
                    onClick={() => setOrderValue(true, 'bridgingDeviceRequired')}
                  >
                    Ja
                  </button>
                  <button
                    className={classNames({ active: !bridgingDeviceRequired })}
                    onClick={() => setOrderValue(false, 'bridgingDeviceRequired')}
                  >
                    Nein
                  </button>
                </div>
              </div>
            </div>
            <div className="text-14">
              <strong>Hinweis:</strong> <span className="text-base-light">In der Regel dauert der Service einer Maschine
              in unserer Werkstatt nur ein paar Tage. Falls du das Gerät während dieser Zeit brauchst,
              geben wir dir ein ähnliches Mietgerät.</span>
            </div>
          </div>
          <div className="box-cta">
            <Button
              onClick={handleCtaClick}
              className={classNames({
                'btn-disabled': !isValid
              })}
              hasRightIcon
              icon="arrow-right"
              // disabled={!description}
            >
              Weiter zu Zubehör
            </Button>
          </div>
          <CartPortal>
            <Button
              onClick={handleCtaClick}
              className={classNames({
                'btn-disabled': !isValid
              })}
              hasRightIcon
              icon="arrow-right"
              // disabled={!description}
            >
              Weiter zu Zubehör
            </Button>
          </CartPortal>
          {showCostApprovalAlert && (
            <BoxModal
              ref={costApprovalAlertRef}
              text="
              Mit einer Kostenfreigabe können wir nach der Diagnose sofort weiterarbeiten, was Zeit und Geldspart.
              Sollte die Freigabe überschritten werden, erhalten Sie das Angebot kostenlos.
              Großhändler erhalten Angebote generell kostenfrei."
              title="Schon gewusst?"
              btnText="Weiter zu Zubehör"
              secondaryCtaText="Kostenfreigabe anpassen"
              btnIcon="arrow-right"
              closeFunction={() => {
                setShowCostApprovalAlert(false);
              }}
              successFunction={handleCtaClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StepProductInfo;
