import classNames from 'classnames';
import type { FC } from 'react';
import { useContext } from 'react';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import ConfigContext from '../../service/context/config';
import useOrderStore from '../../service/store/order';
import { redirectParent } from '../../utils/redirect';
import { getServiceProvider } from '../../utils/service_provider';
import ServiceProviderLogo from '../ServiceProviderLogo';

import Button from './Button';
import Icon from './Icon';
import './Progressbar.scss';

interface IProgressbarProps {
  reset: () => void;
  resetButtonText?: string;
}

const Progressbar: FC<IProgressbarProps> = ({
  reset,
  resetButtonText = 'Eine weitere Abholung beauftragen'
}) => {
  const { order } = useOrderStore(state => state) || {};
  const { name: serviceProviderName } = getServiceProvider();
  const config = useContext(ConfigContext);

  const showCompaintButon = order?.isComplaint && !!config?.complaint_form;

  const [ progress, setProgress ] = useState(0);
  const [ isDone, setIsDone ] = useState(false);

  useEffect(() => {
    const steps: number[] = [ 0, 15, 25, 40, 65, 90, 100 ];

    let stepIndex = 0;
    const interval = setInterval(() => {
      if (stepIndex <= steps.length) {
        setIsDone(false);
        setProgress(steps[stepIndex]);

        if (stepIndex === steps.length) {
          setIsDone(true);

          if (showCompaintButon && config?.complaint_form) {
            redirectParent(config?.complaint_form);
          }
        }

        stepIndex++;
      } else {
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [ config?.complaint_form, showCompaintButon ]);

  return (
    <div className="order-confirmation-container">
      <div className="progressbar-wrapper">
        <CircularProgressbar
          value={progress}
          text={undefined}
          styles={buildStyles({
            strokeLinecap: 'butt'
          })}
        />
        <div className="progressbar-overlay">
          {isDone ? (
            <Icon icon="checkmark" size="32" />
          ) : (
            <ServiceProviderLogo useSmall />
          )}
        </div>
      </div>
      {isDone ? (
        <>
          <div className="confirm-content">
            <p>
              <strong>Vielen Dank! Ihre Anfrage ist eingegangen und wird bearbeitet.</strong>
            </p>
            <p className="confirm-info">
              Sie erhalten zusätzlich noch eine Bestätigungsmail mit allen Auftragsdetails.<br />
              Bei Rückfragen melden wir uns über die Angegebenen Kontaktdaten.
            </p>
          </div>
          <div className="confirm-content">
            {showCompaintButon && (
              <>
                <p>
                  Du hast angegeben, dass der Auftrag eine Reklamation oder ein Garantiefall ist.
                  Bitte öffne den Link und fülle das Formular aus.
                </p>
                <a
                  className="btn"
                  href={config?.complaint_form}
                  target="_blank"
                  rel="noreferrer"
                >
                  Formular öffnen
                </a>
              </>
            )}
            <Button
              className={classNames({
                'btn-ghost': showCompaintButon
              })}
              onClick={reset}
            >
              {resetButtonText}
            </Button>
          </div>
        </>
      ) : (
        <p>
          <strong>
            Einen Moment bitte.<br />
            Ihre Anfrage wird an {serviceProviderName} übermittelt.
          </strong>
        </p>
      )}
    </div>
  );
};

export default Progressbar;
