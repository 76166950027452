import { compact, find, map, size } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import type { AppConfig, AppConfigVos } from '../types';
import { formatCurrency } from '../utils/format';

import './../Cart.scss';

interface ICartAccessoriesProps {
  accessories:  { [p: string]: number } | undefined;
  jaws:  { [p: string]: number } | undefined;
  config: AppConfig | AppConfigVos | null;
  showPrices?: boolean;
}

const CartAccessories: FC<ICartAccessoriesProps> = ({
  accessories,
  jaws,
  config,
  showPrices = true
}) => {
  // const {
  //   accessories, jaws
  // } = useOrderStore(state => state.order) || {};
  // const config = useContext(ConfigContext);

  const accessoriesList = compact(map(accessories, (count, key) => {
    if (!count) {
      return undefined;
    }

    const foundObject = find(config?.accessories, { key });

    if (!foundObject) {
      return undefined;
    }

    const total = count * parseFloat(foundObject.price);

    return foundObject ? {
      key,
      count,
      name: foundObject.name,
      price: foundObject.price,
      total,
      totalFormatted: formatCurrency(total)
    } : undefined;
  }));
  const jawsList = compact(map(jaws, (count, key) => {
    if (!count) {
      return undefined;
    }

    const foundObject = find(config?.jaws, { key });

    if (!foundObject) {
      return undefined;
    }

    const total = count * parseFloat(foundObject.price);

    return foundObject ? {
      key,
      count,
      name: foundObject.name,
      price: foundObject.price,
      total,
      totalFormatted: formatCurrency(total)
    } : undefined;
  }));
  const listToRender = [
    ...accessoriesList,
    ...jawsList
  ];

  return (
    <div>
      <div className="cart-head">Zubehör</div>
      <div className="cart-row">
        <div className="cart-row-inner cart-row-inner-head">
          <div className="amount">Stk.</div>
          <div className="name">Zubehör</div>
          {showPrices && (
            <div className="currency">Einzelpreis</div>
          )}
        </div>
      </div>
      <div className="cart-row">
        {size(listToRender) > 0 ? map(listToRender, accessory => (
          <div key={accessory.key} className="cart-row-inner">
            <div className="amount">
              {accessory.count} &times;
            </div>
            <div className="name">{accessory.name}</div>
            {showPrices && (
              <div className="currency">{formatCurrency(accessory.price)}</div>
            )}
          </div>
        )) : (
          <div className="cart-row-inner">
            <em>Noch kein Zubehör ausgewählt.</em>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartAccessories;
