import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const rootElement = document.getElementById('root');

root.render(
  <React.StrictMode>
    <App domElement={rootElement} />
  </React.StrictMode>
);
